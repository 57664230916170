import Loading from "./lib/Loading";
import Cookie from "./lib/Cookie";
import MoveElement from "./lib/MoveElement"

// function FullPage() {
//     if ($(window).width() >= 1025) {
//         $('#pagepiling').pagepiling({
//             //events
//             onLeave: function(index, nextIndex, direction, section) {
//                 if (direction == 'down') {
//                     $('header').addClass('active')
//                     $('#block__tool').css('bottom', 0)
//                 }
//             },
//             afterLoad: function(anchorLink, index) {
//                 if (index == 1) {
//                     $('header').removeClass('active');
//                     $('#block__tool').css('bottom', 275);
//                 }
//             },
//             afterRender: function() {
//                 $('#block__tool').css('bottom', 275);
//             },
//         });
//     }
//     if (('#pagepiling'.length >= 1) && ($(window).width() >= 1240)) {
//         $('body').css('overflow', 'hidden')
//     } else {
//         $('body').css('overflow', 'auto')
//     }
// }

function FullPage() {
    if (($(window).width() >= 1240) && ('#pagepiling'.length >= 1)) {
        $('#pagepiling').pagepiling({
            menu: null,
            direction: 'vertical',
            verticalCentered: true,
            sectionsColor: [],
            anchors: [],
            scrollingSpeed: 900,
            easing: 'swing',
            loopBottom: true,
            loopTop: false,
            css3: true,
            normalScrollElements: null,
            normalScrollElementTouchThreshold: 5,
            touchSensitivity: 5,
            keyboardScrolling: true,
            sectionSelector: '.section',
            animateAnchor: true,

            //events
            onLeave: function(index, nextIndex, direction, section) {
                if (direction == 'down') {
                    $('header').addClass('active')
                    $('#block__tool').css('bottom', 0)
                }
            },
            afterLoad: function(anchorLink, index) {
                if (index == 1) {
                    $('header').removeClass('active');
                    $('#block__tool').css('bottom', 275);
                }
            },
            afterRender: function() {
                $('#block__tool').css('bottom', 275);
            },
        });
    }
    if ($('#pagepiling').length >= 1 && ($(window).width() >= 1280)) {
        $('body').css('overflow', 'hidden')
    } else {
        $('body').css('overflow', 'auto')
    }
}

function footerNone() {
    if ($('#pagepiling').length > 0) {
        $('footer').addClass('active')
    } else {
        $('footer').removeClass('active')
    }
}

// Slide project-detail
function projectDetailSlide() {
    var pgalleryTop = new Swiper(".project_detail .gallery-top", {
        // spaceBetween: 10,
    });
    var pgalleryThumbs = new Swiper(".project_detail .gallery-thumbs", {
        initialSlide: 1,
        centeredSlides: true,
        slidesPerView: "auto",
        slideToClickedSlide: true,
        spaceBetween: 10,
        navigation: {
            nextEl: ".project_detail_slide_gallery .lnr.lnr-arrow-left",
            prevEl: ".project_detail_slide_gallery .lnr.lnr-arrow-right",
        },
    });

    if ($("section").hasClass("project_detail")) {
        pgalleryTop.controller.control = pgalleryThumbs;
        pgalleryThumbs.controller.control = pgalleryTop;
    }
}

// Home banner
function homeBanner() {
    var swiperhomebanner = new Swiper('.home-banner', {
        loop: true,
        speed: 2500,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        // autoplay: {
        // 	delay: 3500,
        // 	disableOnInteraction: false
        // },
    });
}
const toggleMenuMobile = () => {
    $(".toggle-menu").on("click", function() {
        $(this).toggleClass("active");
        $(this).siblings(".main-nav").toggleClass("active");
        $("body").toggleClass("disabled");
    });
};

function moveNavitem() {
    if ($(window).width() <= 1024.98) {
        $('#desktop').appendTo('nav .nav-mobile');
    } else {
        $('#desktop').appendTo('.main-nav>.nav .icon');
    }
}

function tabsDescription() {
    $('.tabs > li').on('click', function() {
        var $panel = $(this).closest('.tab-panels');
        $panel.find('li.active').removeClass('active');
        $(this).addClass('active');
        var panelToShow = $(this).attr('rel');
        $panel.find('.panel.active').slideUp(300, showNextPanel);

        function showNextPanel() {
            $(this).removeClass('active');
            $('#' + panelToShow).slideDown(300, function() {
                $(this).addClass('active');
            });
        };

    });

}

// Form search
function searchForm() {
    if ($(window).width() <= 992) {
        $('header').each(function() {
            $('.search').click(function() {
                $('.searchbox').slideToggle();
            })
        })
        $(window).scroll(function() {
            if ($(this).scrollTop() > 0) {
                $('.searchbox').slideUp();
            }
        });
    }
}


function listFilter() {
    $('span').click(function(j) {
        var dropDown = $(this).closest('.acc__card').find('.acc__panel');
        $(this).closest('.acc').find('.acc__panel').not(dropDown).slideUp();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.acc__title').removeClass('active');
        } else {
            $('.acc__title').find('span.active').removeClass('active');
            $(this).addClass('active');
            $(this).closest('.acc__card').find('.acc__title').addClass('active');
        }
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });
    if ($(window).width() <= 1024)
        $('.block__title').find('.acc__mobile').on('click', function(e) {
            $('.content__sidebar').find('.acc').toggleClass('active');
            e.preventDefault();
        });
}

// Sub menu mobile
function subMenuMobile() {
    if ($(window).width() <= 1024) {
        $('.submenu-toggle').on('click', function(event) {
            var dropDown = $(this).closest('.nav-item').find('.nav-item__dropdownmenu');
            $(this).closest('.nav-item').find('.nav-item__dropdownmenu').addClass('active');
            $(this).closest('.navbar-nav').find('.nav-item__dropdownmenu').not(dropDown).slideUp();
            if ($(this).hasClass('active')) {
                $(this).addClass('active');
            } else {
                $(this).closest('.navbar-nav').find('.submenu-toggle').removeClass('active');
                $(this).removeClass('active');
            }
            dropDown.stop(false, true).slideToggle();
            event.preventDefault();
        });
    }
}


function DataBG() {
    $('[data-bg]').each(function() {
        $(this).addClass('data-bg lazyload')
    })
}

function setBackground() {
    $("[setBackground]").each(function() {
        var background = $(this).attr("setBackground");
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-size": "cover",
            "background-position": "center center"
        });
    });
    $("[setBackgroundRepeat]").each(function() {
        var background = $(this).attr("setBackgroundRepeat");
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-repeat": "repeat",
        });
    });
}

function checkMain() {
    var heightheader = $('header').outerHeight()
    if ($('#pagepiling').length < 1) {
        $('main').css('padding-top', heightheader)
    }
}

function tool() {
    $('#block__tool').find('.tool-ganeral').on('click', function(j) {
        j.preventDefault();
        $('#block__tool').find('ul').slideToggle()
    })
}
document.addEventListener('DOMContentLoaded', () => {
    // Loading();
    checkMain();
    homeBanner();
    toggleMenuMobile();
    tabsDescription();
    searchForm();
    searchForm();
    listFilter();
    subMenuMobile();
    DataBG();
    setBackground();
    moveNavitem();
    footerNone();
    projectDetailSlide();
    window.addEventListener("resize", () => {
        homeBanner();
        toggleMenuMobile();
        moveNavitem();
    })
    tool();
    FullPage();
});